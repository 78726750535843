<template>
  <div v-if="formData" class="member-create">
    <div class="card-container form-wrapper">
      <p class="title">新增會員</p>
      <BaseElForm ref="form" :model="formData" :rules="formRules" label-position="top">
        <BaseElFormItem label="手機號碼" prop="phone">
          <BaseElInput
            v-model="formData.phone"
            placeholder="請輸入手機號碼"
            clearable
            @input="checkMember"
          />
        </BaseElFormItem>

        <!-- 基本欄位 -->
        <BaseElFormItem
          v-for="field in baseFields"
          :key="field.key"
          :label="handleLabel(field.name)"
          :prop="field.key"
        >
          <BaseElInput
            v-if="field.type === 'name'"
            v-model="formData[field.key]"
            :maxlength="30"
            show-word-limit
            :disabled="disabledAll"
            :placeholder="fieldPlaceHolder(field.type)"
          />
          <BaseElInput
            v-if="field.type === 'email'"
            v-model="formData[field.key]"
            :disabled="disabledAll"
            :placeholder="fieldPlaceHolder(field.type)"
            clearable
          />
          <BirthDayInput
            v-if="field.type === 'birthday'"
            :disabledAll="disabledAll"
            :year.sync="formData.birthday.year"
            :month.sync="formData.birthday.month"
            :date.sync="formData.birthday.date"
          />
          <BaseElSelect
            v-if="['gender'].includes(field.type)"
            v-model="formData.gender"
            :disabled="disabledAll"
            :placeholder="fieldPlaceHolder(field.type)"
          >
            <BaseElSelectOption
              v-for="item in [{label:'男生', value: 'male'}, {label:'女生', value: 'female'}]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              {{ item.label }}
            </BaseElSelectOption>
          </BaseElSelect>
          <AddressInput
            v-if="field.type === 'address'"
            :disabledAll="disabledAll"
            :city.sync="formData.address.city"
            :area.sync="formData.address.area"
            :detail.sync="formData.address.detail"
          />
        </BaseElFormItem>

        <!-- 自定義欄位 -->
        <BaseElFormItem
          v-for="field in customFields"
          v-show="field.type !== 'companyDirectoryFirmTaxId' || (showCompany && field.type === 'companyDirectoryFirmTaxId')"
          :key="field.key"
          :label="field.name"
          :prop="field.key"
        >
          <BaseElInput
            v-if="field.type === 'text'"
            v-model="formData[field.key]"
            :disabled="disabledAll"
            placeholder="請輸入"
            clearable
          />

          <BaseElInput
            v-if="field.type === 'richtext'"
            v-model="formData[field.key]"
            :disabled="disabledAll"
            type="textarea"
            :rows="2"
            placeholder="請輸入"
          />

          <BaseElCheckboxGroup
            v-if="field.type === 'checkbox'"
            v-model="formData[field.key]"
            :disabled="disabledAll"
          >
            <BaseElCheckbox
              v-for="item in field.enum"
              :key="item"
              :label="item"
            />
          </BaseElCheckboxGroup>

          <BaseElRadioGroup
            v-if="field.type === 'radio'"
            v-model="formData[field.key]"
            :disabled="disabledAll"
          >
            <BaseElRadio
              v-for="item in field.enum"
              :key="item"
              :label="item"
            />
          </BaseElRadioGroup>

          <BaseElSelect
            v-if="field.type === 'companyDirectoryFirmTaxId'"
            v-model="formData[field.key]"
            :disabled="disabledAll"
          >
            <BaseElSelectOption
              v-for="item in field.enum || customeEnum[field.type]"
              :key="item.id"
              :label="item.label || item"
              :value="item.value || item"
            />
          </BaseElSelect>

          <RestrictedImageUpload
            v-if="field.type === 'restrictedImage'"
            :fileId.sync="formData[field.key]"
          />
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <MemberExistDialog
      v-if="showMemberExistDialog"
      :member="member"
      @close="showMemberExistDialog=false"
    />
    <SyncMemberDialog v-if="showSyncMemberDialog" :user="user" @close="showSyncMemberDialog=false" />

    <PageFixedFooter
      :confirmLoading="creating"
      :disabledConfirm="disabledAll"
      @cancel="$router.go(-1)"
      @confirm="createUser"
    />
  </div>
</template>

<script>
import AddressInput from '@/components/Input/AddressInput'
import BirthDayInput from '@/components/Input/BirthDayInput.vue'
import MemberExistDialog from './components/MemberExistDialog.vue'
import SyncMemberDialog from './components/SyncMemberDialog.vue'
import {
  CheckUserPhone,
  CreateUserByPhone,
  RegisterShopMemberByUser,
  GetMembersCount,
} from '@/api/member'
import { SimpleFindCompanyContactMenu } from '@/api/company/contactMenu'
import { noEmptyRules, phoneRules, emailRules, lengthRules } from '@/validation'
import formUtils from '@/utils/form'
import { mapGetters } from 'vuex'
import { get, has, omitBy, omit, map, find } from 'lodash'
import { useCompany } from '@/use/company'
import RestrictedImageUpload from '@/components/RestrictedImageUpload'

export default {
  name: 'MemberCreate',
  components: {
    BirthDayInput,
    AddressInput,
    MemberExistDialog,
    SyncMemberDialog,
    RestrictedImageUpload,
  },

  setup () {
    const { useCompanyContactMenu, getShopContactMenuInfo, orgTaxId } = useCompany()
    return {
      useCompanyContactMenu,
      getShopContactMenuInfo,
      orgTaxId,
    }
  },
  data: () => ({
    customeEnum: {},
    creating: false,
    membersCount: 0,
    member: {},
    user: {},
    disabledAll: true,
    showMemberExistDialog: false,
    showSyncMemberDialog: false,
    formData: null,
    formRules: {},
  }),

  computed: {
    ...mapGetters(['shop', 'userPlanLimit']),
    memberRegisterLimit () {
      return get(this.userPlanLimit, 'memberLimit') || 0
    },
    orgId () {
      return get(this.$store.state.shop, 'shopConfig.OrgId')
    },
    registerFields () {
      return get(this.$store.state.org, 'userRegisterConfig')
    },
    baseFields () {
      return this.registerFields.filter(item => item.isBase)
    },
    customFields () {
      return this.registerFields.filter(item => !item.isBase)
    },

    compactAddress () {
      const city = get(this.formData, 'address.city')
      const area = get(this.formData, 'address.area')
      const detail = get(this.formData, 'address.detail')
      return city + area + detail
    },

    compactBirthday () {
      const year = get(this.formData, 'birthday.year')
      const month = get(this.formData, 'birthday.month')
      const date = get(this.formData, 'birthday.date')
      if (!year || !month || !date) return ''
      return `${year}-${month}-${date}`
    },

    baseCompactData () {
      const data = JSON.parse(JSON.stringify(this.formData))
      if (has(data, 'address')) data.address = this.compactAddress
      if (has(data, 'birthday')) data.birthday = this.compactBirthday
      const keys = map(this.customFields, 'key')
      keys.push('phone')
      return omit(omitBy(data, i => this.omitEmpty(i)), ...keys)
    },
    customCompactData () {
      const data = JSON.parse(JSON.stringify(this.formData))
      if (has(data, 'address')) data.address = this.compactAddress
      if (has(data, 'birthday')) data.birthday = this.compactBirthday
      const keys = map(this.baseFields, 'key')
      keys.push('phone')
      return omit(omitBy(data, i => this.omitEmpty(i)), ...keys)
    },
    compactInfoData () {
      return {
        ...this.baseCompactData,
        custom: this.customCompactData,
      }
    },
    showCompany () {
      return this.useCompanyContactMenu && this.orgTaxId(this.registerFields)
    },
  },

  watch: {
    'formData.phone': function (phone) {
      if (!phone) {
        this.disabledAll = true
        if (this.$refs.form) formUtils.resetForm(this.$refs.form)
        this.formData = this.generateFormData(this.registerFields, phone)
        return
      }

      if (phone.trim().length < 10) {
        this.disabledAll = true
        if (this.$refs.form) formUtils.resetForm(this.$refs.form)
        this.formData = this.generateFormData(this.registerFields, phone)
      }
    },
  },
  async mounted () {
    await this.getMemberCount()
    if (this.membersCount >= this.memberRegisterLimit) {
      this.$router.push({
        name: 'MembersManage',
      })
      return
    }

    // if (!this.registerFields) {
    const getSettingError = await this.$store.dispatch('org/getUserInfoSetting', this.orgId)
    if (getSettingError) {
      this.$message.error(getSettingError)
      return
    }
    // }

    this.formRules = this.generateFormRules(this.registerFields)
    this.formData = this.generateFormData(this.registerFields)

    await this.getShopContactMenuInfo({ shopId: this.shop })
    if (!this.useCompanyContactMenu || !this.orgTaxId(this.registerFields)) return
    this.simpleFindCompanyContactMenu()
  },
  methods: {
    async simpleFindCompanyContactMenu () {
      let start = 0
      const limit = 10
      const data = []
      let temp = []
      const [res, err] = await SimpleFindCompanyContactMenu({
        shopId: this.shop,
        start,
        limit,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      start += res.length
      temp = res
      data.push(...res)

      while (temp.length === limit) {
        const [res, err] = await SimpleFindCompanyContactMenu({
          shopId: this.shop,
          start,
          limit,
        })
        if (err) {
          this.$message.error(err)
          return
        }
        start += res.length
        temp = res
        data.push(...res)
      }

      this.$set(this.customeEnum, 'companyDirectoryFirmTaxId', data.map(i => ({
        label: i.name,
        id: i.id,
        value: i.taxId,
      })))
    },
    async getMemberCount () {
      try {
        this.membersCount = await GetMembersCount({
          shopId: this.shop,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    omitEmpty (data) {
      if (typeof data === 'string' || data instanceof String) {
        if (data === '') return true
      }
      if (Array.isArray(data)) {
        if (!data.length) return true
      }
      return false
    },

    generateFormRules (fields) {
      const rules = {}
      fields.forEach(item => {
        if (item.key === 'name') {
          rules[item.key] = [noEmptyRules('請輸入姓名'), lengthRules(1, 30)]
        } else if (item.key === 'email') {
          // if (item.required) rules.email = [noEmptyRules(), emailRules()]
          rules.email = [emailRules(false)]
        }
      })
      rules.phone = [noEmptyRules('請輸入手機號碼'), phoneRules()]
      return rules
    },
    generateFormData (fields, phone) {
      const data = {}
      fields.forEach(item => {
        if (item.key === 'birthday') {
          data.birthday = {}
          data.birthday.year = ''
          data.birthday.month = ''
          data.birthday.date = ''
        } else if (item.key === 'address') {
          data.address = {}
          data.address.city = ''
          data.address.area = ''
          data.address.detail = ''
        } else if (item.type === 'checkbox') {
          data[item.key] = []
        } else {
          data[item.key] = ''
        }
      })
      data.phone = ''
      if (phone) data.phone = phone
      data.name = ''
      return data
    },

    async checkMember (phone) {
      if (phone.length < 10) return
      const pass = /^09\d{8}$/.test(phone)
      if (!pass) return
      try {
        const [res, err] = await CheckUserPhone({
          shopId: this.shop,
          phone,
        })
        if (err) return this.$message.error(err)
        this.member = res.member
        this.user = res.user
        // 已存在使用者或會員
        if (res.user && res.member) this.showMemberExistDialog = true
        if (res.user && !res.member) this.showSyncMemberDialog = true
        if (!res.user && !res.member) this.disabledAll = false
      } catch (error) {
        console.log(error)
      }
    },

    async createUserByPhone () {
      const [res, err] = await CreateUserByPhone({
        shopId: this.shop,
        phone: this.formData.phone,
        info: this.compactInfoData,
      })
      if (err) {
        this.$message.error(err)
        return false
      }

      return res.user
    },

    async registerShopMemberByUser (user) {
      const [res, err] = await RegisterShopMemberByUser({
        shopId: this.shop,
        userId: user.id,
      })
      if (err) {
        this.$message.error(err)
        return false
      }

      this.$router.push({
        name: 'MemberProfile',
        params: {
          id: res.member.id,
          tab: 'info',
        },
      })
    },

    async createUser () {
      if (this.creating) return
      this.creating = true
      if (!(await formUtils.checkForm(this.$refs.form))) {
        this.creating = false
        return
      }
      const user = await this.createUserByPhone()
      if (!user) {
        this.creating = false
        return
      }
      if (user) {
        if (await this.registerShopMemberByUser(user)) return
      }
      this.$message.success('新增會員成功！')
    },

    handleLabel (label) {
      if (label === '地址') return ''
      if (label === '生日') return ''
      return label
    },
    fieldPlaceHolder (fieldType) {
      if (fieldType === 'name') return '請輸入姓名'
      if (fieldType === 'email') return '請輸入信箱'
      if (fieldType === 'gender') return '請選擇性別'
    },
  },
}
</script>

<style lang="postcss" scoped>
.title {
  @apply text-primary-100 font-bold text-lg leading-[21.6px] mb-[40px];
}

.form-wrapper {
  @apply p-[20px] pl-[29px];
}

::v-deep .el-form-item__label{
  @apply text-sub;
}
</style>
