<template>
  <div class="birthday-input">
    <div v-if="!hideLabel" style="line-height: 16.8px; padding-bottom: 8px;"><span v-if="required" class="font-bold" style="color: #F56C6C">*</span> 生日</div>
    <div class="wrapper">
      <BaseElFormItem prop="birthday.year">
        <BaseElSelect v-model="syncYear" :disabled="disabledAll" placeholder="年" @change="syncMonth='', syncDate=''">
          <BaseElSelectOption
            v-for="year in yearsOptions"
            :key="year"
            :label="year"
            :value="year"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem prop="birthday.month">
        <BaseElSelect v-model="syncMonth" :disabled="disabledAll" placeholder="月" @change="syncDate=''">
          <BaseElSelectOption
            v-for="month in monthOptions"
            :key="month"
            :label="month"
            :value="month"
          />
        </BaseElSelect>
      </BaseElFormItem>

      <BaseElFormItem prop="birthday.date">
        <BaseElSelect v-model="syncDate" :disabled="disabledAll" placeholder="日">
          <BaseElSelectOption
            v-for="date in avaliablesDate"
            :key="date"
            :label="date"
            :value="date"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </div>
  </div>
</template>

<script>
import dayjs from '@/lib/dayjs'
import { generateNumbers } from '@/utils/helper'

export default {
  name: 'BirthDayInput',
  props: {
    hideLabel: {
      type: Boolean,
      default: false,
    },
    year: {
      type: [Number, String],
      required: true,
    },
    month: {
      type: [Number, String],
      required: true,
    },
    date: {
      type: [Number, String],
      required: true,
    },
    disabledAll: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {

    yearsOptions () {
      const years = generateNumbers(1930, dayjs().year() + 1, 1)
      return years.sort((a, b) => a - b)
    },
    monthOptions () {
      return generateNumbers(1, 13, 1)
    },
    test () {
      const yearMonth = dayjs(`${this.syncYear}-${this.syncMonth}-1`)
      return yearMonth.daysInMonth()
    },
    avaliablesDate () {
      const yearMonth = dayjs(`${this.syncYear}-${this.syncMonth}-1`)
      const days = yearMonth.daysInMonth()
      return generateNumbers(1, days + 1, 1)
    },
    syncYear: {
      get () { return this.year },
      set (val) {
        return this.$emit('update:year', val)
      },
    },
    syncMonth: {
      get () { return this.month },
      set (val) {
        return this.$emit('update:month', val)
      },
    },
    syncDate: {
      get () { return this.date },
      set (val) {
        return this.$emit('update:date', val)
      },
    },
  },
  methods: {},
}
</script>

<style lang="postcss" scoped>
.wrapper {
  @apply flex gap-[10px];
}

::v-deep.el-select .el-input {
  @apply w-[120px];
}
</style>
